//
// Specific JS for opening action points (with an hash)
//

(function () {
    'use strict';

    var $body = $('body');

    var location = window.location.hash;
    var openActionPoint = location.match(/^#action-point-([a-z\-]*)$/);

    if ( openActionPoint ) {
        var actionPoint = openActionPoint[1];
        var $actionPoint = $('[data-action-point="'+actionPoint+'"]');

        $actionPoint.addClass('open');
        $body.addClass("action-point--open");

        setTimeout( function(){
            var scrollTop = $actionPoint.offset().top - 20;

            $('html, body').animate({
                scrollTop: scrollTop
            }, 500);
        }, 20);
    }

})();
