(function () {
    'use strict';

    var $window = $(window),
        $body = $('body')

    var $actionBar = $('.action-bar--fixed');
    var $tool = $('.tool__container--employer.tool__container--fixed');

    if($actionBar.length > 0) {
        $window.on('scroll', function () {
            if($(this).width() >= 768) {
                if ($(this).scrollTop() > 128) {
                    $actionBar.addClass("fixed");
                    $tool.addClass('fixed');
                } else {
                    $actionBar.removeClass("fixed");
                    $tool.removeClass("fixed");
                }
            } else {
                if ($(this).scrollTop() > 94) {
                    $actionBar.addClass("fixed");
                } else {
                    $actionBar.removeClass("fixed");
                }
            }
        });
    }

    /*
     * Modal
     */
    var $modalTrigger = $('.modal-trigger'),
        $modalCloseTriggers = $('.modal__close, .modal-overlay');

        $modalTrigger.on('click', function(e) {
            e.preventDefault();
            var modalClass = $modalTrigger.data('modal-class');
            $body.toggleClass(modalClass);
        });
    
    function closeModal() {
       $body.removeClass(function (index, css) {
            return (css.match (/(^|\s)modal-open\S+/g) || []).join(' ');
        }); 
    }
    
    $modalCloseTriggers.on('click', function() {
        closeModal();
    });
    
    $body.on("keyup", function (e) {
        if ((e.keyCode || e.which) == 27) { 
            closeModal();
        }
    });

    var $actionBarTooltip = $('.action-bar__select__tooltip'),
        $actionBarTooltipClose = $('.action-bar__select__tooltip__close');


    $actionBarTooltipClose.on('click', function() {
        $actionBarTooltip.addClass('stopAnimation');
        document.cookie =
            'hideActionBarTooltip=true; expires=Fri, 3 Aug 2500 20:47:11 UTC; path=/'


    });

    // video modal
    var $videolinks = $('[href^="https://player.vimeo.com/video/"]');

    if ($videolinks.length) {
        $.each($videolinks, function (i, el) {
            $(el).click(function(e){
                e.preventDefault();
                var href = this.href
                var $modal = $('[data-modal="vimeo-player-modal"]');
                $modal.find('.content-scope').html('<iframe src="' + href + '"></iframe>')
                $body.addClass('___has-active-modal');
                $modal.addClass('___active');
            })
        })
    }


})();


