(function () {
    'use strict';

    var $saveTrigger = $(".save-action-point"),
        savedCondition = "visited",
        $actionPointCount = $(".action-bar__action__amount");

    $saveTrigger.on("click", function(e) {
        e.preventDefault();

        if (!$(this).hasClass(savedCondition)) {

            //add a class for styling and so that it can't be triggered again
            $(this).addClass(savedCondition);

            //changes the message in the button
            $(this).html("opgeslagen");


            $(".action-bar__action").addClass("active");


            $("body").addClass("op");

            // Send the save action to the backend
            var linkID = $(this).attr('data-id');
            if (linkID) {
                $.ajax({
                    url: '/index.php/tools/save_link',
                    type: "post",
                    dataType: "json",
                    data: {
                        linkID: linkID
                    }
                }).done(function(addData) {
                    // Nothing to do
                });
            }

            //demo purposes only
            var currentValue = parseInt($actionPointCount.text(),10);
            var newValue = currentValue + 1;
            setTimeout( function(){
                $("body").removeClass("op");
                $actionPointCount.text(newValue);
            }  , 300 );
        } else {

        }

    });

})();
