(function () {
    'use strict';

    var $body = $('body');
    
    function closeModal() {
        $body.removeClass('___has-active-modal');
        $('.modal-popup.___active').removeClass('___active');
    }
    
    $body.on('click', '[data-modal-open]', function (event) {

        var modalId  = $(this).data('modal-open');
        var $modal = $('[data-modal="'+modalId+'"]');

        $body.addClass('___has-active-modal');
        $modal.addClass('___active');
    });

    $body.on('click', '[data-modal-close]', function (event) {
        closeModal();
    });

    $body.on("keyup", function (e) {
        if ((e.keyCode || e.which) == 27) { 
            closeModal();
        }
    });

})();
