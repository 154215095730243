(function () {
    'use strict';

    // $(".action-bar__select, .sector-select__close").on("click", function(e){
    //     e.preventDefault()
    //     $("body").toggleClass("sector-select--open");
    // });


    var dropdown_targets = '[data-dropdown-target]';
    var dropdown_closures = '.sector-select__close'


    $('.container').on('click', dropdown_targets, function(event) {
        event.preventDefault();
        var targetElement = event.target;
        var removeTarget =  $('.container').find('.sector-select--open');
        removeTarget.removeClass('sector-select--open');
        var $target = $(targetElement).data('dropdown-target');
        $('[data-dropdown="'+ $target +'"]').toggleClass("sector-select--open")
    });


    $('.container').on('click', dropdown_closures, function(event){
        event.preventDefault();
        var targetElement = event.target;
        $(targetElement).closest('.sector-select--open').toggleClass('sector-select--open');
    })


    $(document).mouseup(function (e)
    {
        var container = $(".sector-select");

        var $target = $('.container').find('.sector-select--open');

        if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            $target.removeClass("sector-select--open");
        }
    });


})();
