(function () {
    'use strict';

    var $body = $('body');

    var scrollDurationBase = 250; // minimal 250ms
    var scrollDurationMultiplier = 0.05; // every pixel means 0.05 extra ms scroll duration 
    var scrollTopMargin = 20; // the number of pixels the element scroll above the element

    $body.on("click", "a[href^='#']", function(event) {
        // prevent the default action
        event.preventDefault();
        // select the element (based upon id) to scroll to
        var elementId = $(this).attr('href');
        var $scrollToElement = $(elementId);

        if ( $scrollToElement.length ) {
            var currentOffsetTop = $(window).scrollTop();
            var offsetTop = $scrollToElement.offset().top - scrollTopMargin;
                // check for trying to scroll further than possible
                var maxOffsetTop = $(document).height() - $(window).height();
                offsetTop = offsetTop < maxOffsetTop ? offsetTop : maxOffsetTop;

            var flexScroll = ((offsetTop-$(window).scrollTop())*scrollDurationMultiplier);
            var scrollDuration = scrollDurationBase + flexScroll;
            
            $('html, body').animate({
                scrollTop: offsetTop
            }, scrollDuration);
        } else if ( elementId === '#top' ) {
            $('html, body').animate({
                scrollTop: 0
            }, scrollDurationBase);
        }
    });

})();
