(function () {
    'use strict';

    $(document).ready( function() {

        var $document = $(document),
            $body = $('body'),
            $emailInput = $("[data-email-trigger]"),
            $emailOverview = $("[data-email-list]"),
            $emailAdd = $("[data-email-add]"),
            $emailErrorWrapper = $("[data-email-error-msg]"),
            $emailErrorMsg = $emailErrorWrapper.data("email-error-msg"),
            $emailErrorString = "<div class='error-msg'>" + $emailErrorMsg + "</div>",
            $messageInput = $("[data-message-trigger]"),
            $messageErrorWrapper = $("[data-message-error-msg]"),
            $messageErrorMsg = $messageErrorWrapper.data("message-error-msg"),
            $messageErrorString = "<div class='error-msg'>" + $messageErrorMsg + "</div>";

        $document.on("click", '[data-email-add]', function(e){
            e.preventDefault();
            addEmail();
        });



        $document.on("click", '[data-email-remove]', function(e){
            e.preventDefault();
            var thisP = $(this).parent();
            $(this).parent("[data-email-contant]").remove();
        });

        $emailInput.on("keypress", function(e){
             if (e.keyCode == 13) {
                 e.preventDefault();
                addEmail();
            }
        });


        function addEmail() {

            var thisVal = $emailInput.val(),
                thisString = "<div class='emailresult' data-email-contant='"+thisVal+"'><span>" + thisVal + "</span><input class='input-hidden' type='email' name='email[]' value='" + thisVal + "'><a href='#' class='remove-email' data-email-remove></a></div>";

            if( !isValidEmailAddress( thisVal ) ) {
                $emailInput.focus();
                if($emailErrorWrapper.children().length == 0) {
                    $emailErrorWrapper.append($emailErrorString);
                }
            } else {
                $emailOverview.append(thisString);
                $emailInput.val("");
                $emailErrorWrapper.html("");
            }
        }

        function isValidEmailAddress(emailAddress) {
            var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
            return pattern.test(emailAddress);
        }

        $body.on('submit', '[data-email-form]', function(e) {
            var emailVal = $emailInput.val();
            var messageVal = $messageInput.val();

            var validMail = false;
                    console.log( $emailErrorWrapper.children().length);
                if ( $("[data-email-list]").children().length > 0 ) {

                    if ( emailVal.length === 0 ) {
                        validMail = true;
                    } else if ( isValidEmailAddress( emailVal ) ) {
                        validMail = true;
                    }
                } else if ( isValidEmailAddress( emailVal ) ) {
                    validMail = true;
                }

            var validMessage = false;
                //if ( messageVal.length > 10 ) {
                    validMessage = true;
                //}
                

            if ( validMail && validMessage ) {

                $emailErrorWrapper.html("");
                $messageErrorWrapper.html("");

            } else {

                e.preventDefault();

                if( !validMail ) {
                    $emailInput.focus();

                    if( $emailErrorWrapper.children().length == 0 ) {
                        $emailErrorWrapper.append($emailErrorString);
                    }
                } else {
                    $emailErrorWrapper.html("");
                }

                if( !validMessage ) {
                    if($messageErrorWrapper.children().length == 0) {
                        $messageErrorWrapper.append($messageErrorString);
                    }
                } else {
                    $messageErrorWrapper.html("");
                }
            }
        });
    });





})();
