(function () {
    'use strict';


    $(".tool__subject__trigger").on("click", function(e){
        e.preventDefault()
        $(".action-subject__item").removeClass("open");
        $("body").removeClass("action-point--open");
        $("body").toggleClass("subject-menu--open");
    });

    $(document).mouseup(function (e)
    {
        var container = $(".subject-menu");

        if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            $("body").removeClass("subject-menu--open");
        }
    });


})();