(function () {
    'use strict';

    $('.to-do-list .remove-item').on('click', function (e) {
        e.preventDefault();

        var $btn  = $(this);
        var $item = $btn.closest('.to-do-list__item');
        var $list = $btn.closest('.to-do-list');

        if (!$item.siblings().length) {
            $list.addClass('removed').slideUp(300);
            handleEmptyActionPointList($btn);
            removeItem($item);
        } else {
            removeItem($item);
        }

        var linkID = $btn.attr('data-id');
        if (linkID) {
            $.ajax({
                url: '/index.php/tools/remove_link',
                type: "post",
                dataType: "json",
                data: {
                    linkID: linkID
                }
            }).done(function(removeData) {
                //var newCount = parseInt($("#saved-links-count").html() - 1);
                //$("#saved-links-count").html(newCount);
            });
        }
    });

    $('.saved-links-list .remove-item').on('click', function (e) {
        e.preventDefault();

        var $btn     = $(this);
        var $item    = $btn.closest('.saved-links-list__item');
        var $wrapper = $btn.closest('.action-point-list__footer');

        if (!$item.siblings().length) {
            $wrapper.addClass('removed').slideUp(300);
            handleEmptyActionPointList($btn);
        } else {
            removeItem($item);
        }
    });

    function removeItem($item) {
        $item.slideUp(300, function () {
            $item.remove();
        });
        setSavedLinksNumber();
    }

    function handleEmptyActionPointList($btn) {
        var $actionPointList = $btn.closest('.action-point-list');

        if (
            ($actionPointList.find('.to-do-list.removed').length || !$actionPointList.find('.to-do-list').length) &&
            ($actionPointList.find('.action-point-list__footer.removed').length  || !$actionPointList.find('.action-point-list__footer').length)
        ) {
            $actionPointList.slideUp(500);
        }
    }
    
    function setSavedLinksNumber() {
        var newCount = parseInt($('[data-save-counter]').html() - 1);
        $('[data-save-counter]').html(newCount);
    }
})();
