$(window).ready(function(){
	
	/** Variables */
	var subjectID;
	var goToAction = false;
	
	/** Elements */
	var checklist = $('#checklist');
	var checklistItems;
	
	var saveChecklistAnswer = function(value,qID,subjectid){
		$.ajax({
			type: "POST",
			url: '/index.php/tools/vraagmodule/checklist',
			data: {
				id: qID,
				subjectID :subjectid,
				answer: value
				
			},
			success: function(){},
			dataType: 'json'
			});
	}
	
	/** Actions */
	var switchChecklistAnswer = function(){
		var item = $(this);
		var others = item.parent().find('.answer');
		
		
		var id = item.parent().attr('id').split('_');
		var id = id[1];
		subjectID = item.parent().attr('data-subject');
		
		
		
		item.click(function(){
			others.removeClass('checked');
			item.addClass('checked');
			saveChecklistAnswer(item.attr('data-val'),id,subjectID);
			checkChecklistBlock();
		});
	}
	
	var checkChecklistBlock = function(){
		var overallStatus = true;
		
		checklistItems.each(function(){
			var status = true;
			var checklistItem = $(this);
			
			checklistItem.find('li.question-wrapper').each(function(){
				var question = $(this);
				if (!question.find('.checked').length) status = false;
			});
			
			if (status){
				
				var next = checklistItem.next();
				
				if(next.length == 0){
					//show button
				}else{
					checklistItem.next().addClass('active');
				}
				
				checklistItem.addClass('finished');
				//checklistItem.removeClass('active');
				
			}
			
			if (!status) overallStatus = status;
		});
		
		if (overallStatus && !goToAction) {			
			goToAction = true;
			var session_key = $("#session_key").val();
			checklist.append($('<a href="/actions/'+ subjectID +'/1/?qKey=' + session_key + '" class="go-to-actions">Opslaan</a>'));
		}
	}
	
	/** Events */
	
	/** Custom */
	var answerInput = $('#answer');
	$('.answerbutton').each(function(){
		
		var button = $(this);
		
		
		button.click(function(e){
			
			e.preventDefault();
			answerInput.val(button.attr('data-answerid'));
			
			$('form').submit();
		});
		
		
	});
	

	// Checklist
	var count = 0;
	if (checklist.length) {
		checklistItems = checklist.find('> li');
		
		checklistItems.each(function(){
			var item = $(this);
			var trigger = item.find('.title');
			var answers = item.find('.answer');
			
			trigger.click(function(){
				item.toggleClass('active');
			});
			
			answers.each(switchChecklistAnswer);			
		});
		
		checkChecklistBlock();
	}
	
	
	$('.column.info').find('a').each(function(){
		
		$(this).click(function(e){
			if($(this).attr('href') == "#"){
				e.preventDefault();
				
			}
		})
		if($(this).attr('href') == "#"){
			var hover;
			var title = "";
			$(this).hover(
				function(e){
					e.preventDefault();
					title = $(this).attr('title');
					$(this).attr('title','');
					hover = $('<div class="hover">'+title+'</div>');
					hover.appendTo('body');
					hover.css('left',e.pageX+5);
					hover.css('top',e.pageY-45);
				},
				
				function(){
					$(this).attr('title',title);
					hover.remove();	
				}
				
			)
		}
		
	});
	
	// Printing
	$('#printbutton').click(function(e){
		e.preventDefault();
		
		var checkListItems = $('#checklist li');
		if (checkListItems.length) {
			checkListItems.addClass('active');
		}
		
		window.print();
		
	});
	
	// gegevens form checkboxes
	$(document).ready(function() {
		$('#gegevens-form').find('.show-checkboxes').on('change', function() {
			var $labels = $(this).parents('#gegevens-form').find('.hidden-checkbox-label');
			if ($(this).is(':checked')) {
				$labels.css('display', 'block')
			} else {
				$labels.css('display', 'none')
			}
		})
	})

	// change all img tags to svg

	$(document).ready(function() {
		$('img.svg[src$=".svg"]').each(function() {
			var $img = jQuery(this);
			var imgURL = $img.attr('src');
			var attributes = $img.prop("attributes");
	
			$.get(imgURL, function(data) {
				// Get the SVG tag, ignore the rest
				var $svg = jQuery(data).find('svg');
	
				// Remove any invalid XML tags
				$svg = $svg.removeAttr('xmlns:a');
	
				// Loop through IMG attributes and apply on SVG
				$.each(attributes, function() {
					$svg.attr(this.name, this.value);
				});
	
				// Replace IMG with SVG
				$img.replaceWith($svg);
			}, 'xml');
		});

		$('[data-fadeout-time]').each(function(i, el) {
			setTimeout(function() {
				$(el).fadeOut(400)
			}, $(el).data('fadeout-time'))
		})
	});

});