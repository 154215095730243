(function () {
    'use strict';

    var $target = $("[data-sticky-action-bar]");
    
    if ( $target.length ) {
        var offset = $target.offset().top;
        
        function scrollEvent() {
            var scrolled = $(window).scrollTop();

            if( scrolled > offset ) {
                $target.addClass("___is-fixed").parent().addClass("has-sticky-actionbar");
            } else {
                $target.removeClass("___is-fixed").parent().removeClass("has-sticky-actionbar");
            }
        }
        
        $(window).scroll(function (e) {
            return scrollEvent();
            /*return window.innerWidth < 480 ? void target.removeClass("fixed").parent().removeClass("has-sticky-actionbar") : void scrollEvent()*/
        });
    }

})();