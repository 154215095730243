//
// Specific JS for opening result on clicking a specific button
//

(function () {
    'use strict';

    var $body = $('body');
    
    $body.on("click", "[data-scrollto-result]", function(e) {
        var $target = $(this).data('scrollto-result');
        var $actionPoint = $('[data-action-point="'+$target+'"]');
        
        $actionPoint.addClass('open');
        $body.addClass("action-point--open");

        setTimeout( function(){
            var scrollTop = $actionPoint.offset().top - 20;

            $('html, body').animate({
                scrollTop: scrollTop
            }, 500);
        }, 20);
        
        return false;
    });
    

})();
