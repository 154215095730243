(function () {
    'use strict';

    var $body = $("body");


    $(".action-subject__item > a").on("click" ,function(e){
        var $actionSubject = $(this).parent();

        e.preventDefault();
        $(".action-subject__item").removeClass("open");
        $(this).parent().addClass("open visited");
        $body.removeClass("subject-menu--open");
        $body.addClass("action-point--open");

        //
        // Scroll to opened .action-point

        if ( $(window).width() > 767 ) { // $screen-sm
            setTimeout( function(){
                var scrollTop = $actionSubject.offset().top - 22;
                    if( $('[data-sticky-action-bar]').length > 0 ) {
                        scrollTop = scrollTop - $('[data-sticky-action-bar]').height();
                    }
                $('html, body').animate({
                    scrollTop: scrollTop
                }, 500);
            }, 20);
        }
    });

    $(".mobile-bar__back, [data-close-action-point]").on("click" ,function(e){

        e.preventDefault();
        $(".action-subject__item").removeClass("open");
        $body.removeClass("action-point--open");
    });

    $(".action-point__close").on("click", function(e) {

        e.preventDefault();
        $(".action-subject__item").removeClass("open");
        $body.removeClass("action-point--open");

    });

})();